import React from 'react';
import ReactDom from 'react-dom';

import {useCookieModal} from './useCookieModal.js';

import './CookieModal.css';

export default function CookieModal({community_name}) {
  const {open, acceptCookies} = useCookieModal(community_name);

  if (!open) {
    return null;
  }

  return ReactDom.createPortal(
    <div className="cookie_modal">
      <div className="cookie_content_area">
        <div className="cookie_message">
          We’ll use cookies to improve and customize your experience if you
          continue to browse. You can read more about how we use cookies in our
          pages at{' '}
          <a href="https://team.drund.com/page/cookies/">
            drund.com/page/cookies
          </a>
          . Is it OK if we also use cookies to show you personalized ads?
        </div>
        <div className="cookie_button_area">
          <a onClick={acceptCookies} className="cookie_button">
            Yes, Accept Cookies
          </a>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
}
