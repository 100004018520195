import React from 'react';
import ReactDom from 'react-dom';
import {useClickOutside} from '../../hooks/useClickOutside';
import './modal.css';

export default function Modal({open, children, title, onClose, hideClose}) {
  let modalRef = useClickOutside(open ? onClose : null);

  if (!open) return null;

  return ReactDom.createPortal(
    <React.Fragment>
      <div className="modal_overlay contact_overlay">
        <div ref={modalRef} className="modal modal-shadow flex-grow-1">
          {!hideClose && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
              className="contact-close modal-close close"
            >
              <svg
                viewBox="0 0 32 32"
                className="svg-icon svg-icon-24x24 svg-icon-dark-gray icon-ic_close "
              >
                <use href="#icon-ic_close"></use>
              </svg>
            </a>
          )}

          <header className="modal_header modal_wrapper">
            <h3 className="modal_header_text">{title}</h3>
          </header>
          <section className="modal_wrapper modal_content">{children}</section>
        </div>
      </div>
    </React.Fragment>,
    document.getElementById('modal-root')
  );
}
