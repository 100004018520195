import React, {useState} from 'react';

import './Header.css';
import NavItem from './NavItem.js';
import useResize from '../../../../hooks/useResize.js';
import SentryTest from '../../../../components/SentryTest/SentryTest';

export default function Header({nav, logo_image, environment}) {
  let [dropdownOpen, setDropdownOpen] = useState(false);
  useResize(() => setDropdownOpen(false));

  const navItemsJsx = nav.pages.map((item) => (
    <NavItem key={item.id} {...item} />
  ));

  if (environment.toLowerCase().trim() == 'development') {
    navItemsJsx.push(<SentryTest key={-1} />);
  }

  return (
    <div className="navbar">
      <div className="container h-100">
        <nav className="custom-menu d-flex flex-grow-1 h-100">
          <button
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className={'nav-button d-md-none' + (dropdownOpen ? ' active' : '')}
          >
            <svg
              viewBox="0 0 32 32"
              className="rotate svg-icon svg-icon-24x24 icon-ic_menu flex-shrink-0"
            >
              <use xlinkHref="#icon-ic_menu"></use>
            </svg>
          </button>

          {logo_image && (
            <a
              href={nav.urls.home}
              className="d-flex align-items-center mr-md-4 mx-2 p-lg-0"
            >
              <img className="community-icon" src={logo_image} />
            </a>
          )}

          <ul className="links d-md-flex flex-grow-1 m-0 pl-0 pr-lg-3">
            {navItemsJsx}
          </ul>
        </nav>
      </div>
    </div>
  );
}
