import {useEffect} from 'react';

export default function useResize(callback) {
  useEffect(() => {
    var navTimeout;

    let listenerFunction = () => {
      clearTimeout(navTimeout);
      navTimeout = setTimeout(callback, 100);
    };

    window.addEventListener('resize', listenerFunction);

    return () => {
      window.removeEventListener('resize', listenerFunction);
    };
  });
}
