import React, {createContext} from 'react';

import '../../css/Index.css';

import Header from '../../components/Header/Header.js';
import HeroImage from '../../../../components/HeroImage/HeroImage.js';
import Footer from '../../components/Footer/Footer.js';

import Modal from '../../../../components/Modal/Modal';
import ContactForm from '../../../buckeye_report/components/ContactForm/ContactForm.js';
import CookieModal from '../../../buckeye_report/components/CookieModal/CookieModal.js';

import {useModal} from '../../../../hooks/useModal';

import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

Sentry.init({
  dsn: 'https://44501001aee04a2c84d64013a75c7326@o153364.ingest.sentry.io/6311836',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0
});

// Provider and Consumer are connected through their "parent" context
const {Provider, Consumer} = createContext();

export default function BasePage(props) {
  const {modalOpen, toggleModal} = useModal();

  return (
    <React.Fragment>
      <Header
        mobile_preview={props.mobile_preview}
        nav={props.nav}
        logo_image={props.logo_image}
        environment={props.environment}
      />
      <HeroImage hero_image={props.hero_image} />

      <Provider value={toggleModal}>{props.children}</Provider>

      <Footer
        mobile_preview={props.mobile_preview}
        footer={props.footer}
        logo_image={props.logo_image}
        community_name={props.community.name}
        toggleModal={toggleModal}
      />

      <CookieModal community_name={props.community.name} />

      <Modal
        open={modalOpen}
        onClose={() => toggleModal(false)}
        title="Contact Us"
      >
        <ContactForm
          onClose={() => toggleModal(false)}
          urls={props.footer.urls}
        />
      </Modal>
    </React.Fragment>
  );
}

export {Consumer as ModalConsumer};
