import React from 'react';
import {createRoot} from 'react-dom/client';
import {Container, Row, Col} from 'react-bootstrap';

import BasePage, {ModalConsumer} from '../Base/Base';
import WYSIWYG from '../../../../components/WYSIWYG/WYSIWYG';

function CustomPage(props) {
  return (
    <BasePage {...props}>
      <Container
        id="container"
        className={'pb-5 ' + (props.mobile_preview ? '' : 'pt-4')}
      >
        <Row id="col1" className="justify-content-center">
          <Col lg={8}>
            <ModalConsumer>
              {(value) => {
                return (
                  <WYSIWYG
                    htmlContent={props.wysiwyg_1_clean}
                    toggleModal={value}
                  />
                );
              }}
            </ModalConsumer>
          </Col>
          {props.two_column && props.wysiwyg_2_clean && (
            <Col lg={4}>
              <ModalConsumer>
                {(value) => {
                  return (
                    <WYSIWYG
                      htmlContent={props.wysiwyg_2_clean}
                      toggleModal={value}
                    />
                  );
                }}
              </ModalConsumer>
            </Col>
          )}
        </Row>
      </Container>
    </BasePage>
  );
}

createRoot(document.getElementById('root')).render(
  React.createElement(CustomPage, window.props)
);
