import React, {useRef} from 'react';

import {useCustomPageJS} from '../../hooks/useCustomPageJS.js';

/*
    Insert custom page content into page,
    dangerouslySetInnerHtml allows css and script tags
    to be inserted and not escaped, it won't run inserted
    js and it will need to useCustomPageJS to run it.
*/

export default function WYSIWYG({htmlContent, toggleModal}) {
  const customPageContent = useRef(null);
  useCustomPageJS(customPageContent, toggleModal);

  return (
    <div
      ref={customPageContent}
      className="mw-100 text-break"
      dangerouslySetInnerHTML={{__html: htmlContent}}
    ></div>
  );
}
