// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../../styles/globals/main.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../../styles/globals/normalize.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../../styles/globals/entry-override.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../../styles/globals/icons.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../../styles/globals/utils.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../../styles/globals/image-utils.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!./general/structure.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!./general/sponsors.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!./general/typography.css";
import ___CSS_LOADER_AT_RULE_IMPORT_9___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!./general/footer.css";
import ___CSS_LOADER_AT_RULE_IMPORT_10___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!./general/buttons.css";
import ___CSS_LOADER_AT_RULE_IMPORT_11___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!./general/modal.css";
import ___CSS_LOADER_AT_RULE_IMPORT_12___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!./general/search-bar.css";
import ___CSS_LOADER_AT_RULE_IMPORT_13___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!./general/video-players.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Merriweather:wght@400;700&family=Montserrat:wght@400;500;600;700&family=Lora:wght@400;700&family=Open+Sans:wght@400;600;700&family=Playfair+Display:wght@700&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_10___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_11___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_12___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_13___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
