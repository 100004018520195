import {useState} from 'react';
import Cookies from 'js-cookie';

export const useCookieModal = (community_name) => {
  const [open, setOpen] = useState(
    !Cookies.get(`drund_${community_name}_accept_cookies`)
  );

  const acceptCookies = () => {
    // issue with the cookie expiring, going to try 999999 days to see if it persists better.
    Cookies.set('drund_' + community_name + '_accept_cookies', 1, {
      expires: 999999
    });
    setOpen(false);
  };

  return {
    open,
    acceptCookies
  };
};
