import React from 'react';

export default function Footer({
  footer,
  logo_image,
  community_name,
  toggleModal
}) {
  let footerJsx = footer.nav_list.map(({id, title, link}) => (
    <li key={id}>
      <a href={link}>{title}</a>
    </li>
  ));

  return (
    <footer className="page-footer d-flex">
      <div className="container my-5">
        {logo_image && (
          <div className="d-flex justify-content-center footer-logo">
            <a href={footer.urls.home}>
              <img className="community-icon" src={logo_image} />
            </a>
          </div>
        )}

        <div className="d-flex justify-content-center mb-3">
          <ul className="footer-list d-flex justify-content-center flex-wrap list-unstyled mb-0">
            {footerJsx}

            <li>
              <a
                href="#"
                onClick={toggleModal}
                className="contact_trigger contact_link"
              >
                Contact Us
              </a>
            </li>

            <li>
              <a
                href="https://support.drund.com/hc/en-us"
                target="_blank"
                rel="noreferrer"
              >
                Support
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex justify-content-center">
          <div className="body3 copyright">
            Copyright {new Date().getFullYear()} {community_name}. All rights
            reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
