import React from 'react';

export default function NavItem({title, current_page, link, sub_pages}) {
  let subPagesJsx = [];
  if (sub_pages) {
    subPagesJsx = sub_pages.map(({id, title, link}) => (
      <li key={id}>
        <a
          className="subnav_item subnav_padding body d-block ellipsis"
          href={link}
        >
          {title}
        </a>
      </li>
    ));
  }

  return (
    <li
      className={`nav-item position-relative m-0 ${
        sub_pages ? ' sub_nav_trigger' : ''
      } ${current_page ? ' active' : ''}`}
    >
      <span className="h-100 d-flex align-items-center justify-content-between">
        <a
          className="px-lg-3 py-lg-0 h-100 h4 mb-0 d-flex align-items-center nav-link flex-grow-1"
          href={link}
        >
          <span className="ellipsis">{title}</span>
        </a>
        {sub_pages && (
          <a href="#" className="dropdown-arrow flex-shrink-0 p-2">
            <svg
              viewBox="0 0 32 32"
              className="rotate svg-icon svg-icon-24x24 icon-ic_chevron_right svg-icon-gray flex-shrink-0"
            >
              <use xlinkHref="#icon-ic_chevron_right"></use>
            </svg>
          </a>
        )}
      </span>
      <menu className="subnav p-0 m-0 w-100">{subPagesJsx}</menu>
    </li>
  );
}
