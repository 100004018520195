import React from 'react';

import {useContactForm} from '../../../drund_com/components/ContactForm/useContactForm.js';

export default function ContactForm({onClose, urls}) {
  const {inputs, handleInputChange, handleSubmit} = useContactForm(
    onClose,
    urls
  );

  return (
    <form onSubmit={handleSubmit} className="form contact_form">
      <div id="div_id_full_name" className="form-field">
        <label htmlFor="id_full_name" className="contact-text requiredField">
          Name<span className="asteriskField">*</span>{' '}
        </label>
        <div className="controls input-wrapper">
          <input
            onChange={handleInputChange}
            value={inputs.full_name}
            type="text"
            name="full_name"
            id="id_full_name"
            required=""
            placeholder="Ex: John Do"
            className="contact-text styled-input input input-width textinput textInput required"
            maxLength="35"
          />
        </div>
        <div className="contact-text hidden_error mt-2">
          Please fill in this field
        </div>
      </div>

      <div id="div_id_email_address" className="form-field">
        <label
          htmlFor="id_email_address"
          className="contact-text requiredField"
        >
          Email address<span className="asteriskField">*</span>
        </label>
        <div className="controls input-wrapper">
          <input
            onChange={handleInputChange}
            value={inputs.email_address}
            type="text"
            name="email_address"
            id="id_email_address"
            required=""
            placeholder="Ex: John@email.com"
            className="contact-text styled-input input input-width textinput textInput required"
            maxLength="255"
          />
        </div>
        <div className="contact-text hidden_error mt-2">
          Please fill in this field
        </div>
      </div>

      <div id="div_id_phone_number" className="form-field">
        <label htmlFor="id_phone_number" className="contact-text">
          Phone Number
        </label>
        <div className="controls input-wrapper">
          <input
            onChange={handleInputChange}
            value={inputs.phone_number}
            type="text"
            name="phone_number"
            id="id_phone_number"
            placeholder="Ex: 123-456-7890"
            className="contact-text styled-input input input-width textinput textInput"
            maxLength="255"
          />
        </div>
      </div>

      <div id="div_id_message" className="form-field">
        <label htmlFor="id_message" className="contact-text requiredField">
          Message<span className="asteriskField">*</span>
        </label>
        <div className="controls input-wrapper">
          <textarea
            onChange={handleInputChange}
            value={inputs.message}
            type="text"
            name="message"
            id="id_message"
            required=""
            className="contact-text styled-input input input-width textinput textInput required"
            rows="7"
            placeholder="Enter message here"
          ></textarea>
        </div>
        <div className="contact-text hidden_error mt-2">
          Please fill in this field
        </div>
      </div>
      <div className="submit-area d-flex justify-content-end">
        <button
          onClick={handleSubmit}
          type="submit"
          className="modal-submit-text submit-button contact_submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
}
